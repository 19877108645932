import queryString, { ParsedQuery } from 'query-string';

export const getUrlWithTrailingSlash = (url: string) => {
  const parsedUrl = queryString.parseUrl(url, { parseFragmentIdentifier: true });
  return queryString.stringifyUrl({ ...parsedUrl, url: parsedUrl.url.endsWith('/') ? parsedUrl.url : parsedUrl.url + '/' });
};

const absoluteUrlPattern = /^https?:\/\//i;
const withjoyDotComUrlPattern = /^https:\/\/(.*\.withjoy.com)|(withjoy.com)\//i;
const demoEventHandle = 'amyplusdave';

export const isExternalUrl = (url: string | undefined) => {
  return url && absoluteUrlPattern.test(url) && !withjoyDotComUrlPattern.test(url);
};

const externalRootUrlsMap: { readonly [key in string]: string } = {
  stage: 'https://rehearsal.withjoy.com',
  development: 'https://dev.withjoy.com',
  production: 'https://withjoy.com',
};

const internalRootUrlsMap: { readonly [key in string]: string } = {
  local: 'localhost:8000',
  stage: 'https://rehearsal.withjoy.com',
  development: 'https://dev.withjoy.com',
  production: 'https://withjoy.com',
};

const blissGatewayUrlMap: { readonly [key in string]: string } = {
  local: 'https://dev.withjoy.com', // used for local development
  stage: 'https://rehearsal.withjoy.com',
  development: 'https://dev.withjoy.com',
  production: 'https://withjoy.com',
};

const env = process.env.GATSBY_ENV || process.env.NODE_ENV!;

/**
 * get root url for non-marcom app, based on current marcom deployment env
 */
export const EXT_APP_ROOT_URL = externalRootUrlsMap[env] || externalRootUrlsMap['production'];

/**
 * get root url for internal marcom routes, based on current marcom deployment env
 */
export const INTERNAL_ROOT_URL = internalRootUrlsMap[env] || internalRootUrlsMap['production'];

/**
 * get root url for bliss gateway endpoints
 */
export const BLISS_GATEWAY_ROOT_URL = blissGatewayUrlMap[env] || internalRootUrlsMap['production'];

/**
 * Take url path: /login, /createwedding or /wedding-website and send to appropriate internal or external root url.
 */
export function getRootUrl(urlPath: string): string {
  const externalPaths: { readonly [key in string]: boolean } = {
    '/createwedding': true,
    '/login': true,
    '/amyplusdave': true,
    '/designs': true,
    '/event/edit/card': true,
    '/cards': true,
  };

  if (externalPaths[urlPath]) {
    return EXT_APP_ROOT_URL + urlPath;
  }

  return INTERNAL_ROOT_URL + urlPath;
}

/**
 * Get url according to the env and build (useful to run e2e test local/CI)
 */
export const getRootBuildUrl = () => {
  const query = { 'feature.marcom': process.env.SHORT_SHA };
  return queryString.stringifyUrl({ url: INTERNAL_ROOT_URL, query });
};

const baseCreateWeddingUrl = getRootUrl('/createwedding');

const pageToScenarioMap: { [key in string]: SignUpScenario } = {
  'wedding-invitations': 'invitation',
};

// TODO: move all createWedding to the generic getCreateEventUrl
export const getCreateWeddingUrl = (fromPage: MarcomPageName | undefined) => {
  const resultUrl = queryString.parseUrl(baseCreateWeddingUrl);
  const queryParams = resultUrl.query;
  if (fromPage && fromPage !== 'home') {
    queryParams.scenario = pageToScenarioMap[fromPage] ?? fromPage;
  }
  return queryString.stringifyUrl(resultUrl);
};

export const getCreateEventUrl = (fromPage: MarcomPageName | undefined) => {
  const page = fromPage === 'baby-registry' ? 'createbabyregistry' : 'createwedding';
  const resultUrl = queryString.parseUrl(getRootUrl(`/${page}`));
  const queryParams = resultUrl.query;
  if (fromPage && fromPage !== 'home') {
    queryParams.scenario = pageToScenarioMap[fromPage] ?? fromPage;
  }
  return queryString.stringifyUrl(resultUrl);
};

export const getCardCategoryFromVariationType = (variationType: string) => {
  const map: { readonly [key in string]: string } = {
    invite: 'invitation',
    savethedate: 'saveTheDate',
  };

  return map[variationType] || '';
};

const VARIATION_TYPE_TO_NAMED_CATEGORY_PATH: Record<'savethedate' | 'invite', string> = {
  savethedate: 'save-the-date',
  invite: 'invitation',
};

export const getGalleryCreateWeddingUrl = (
  cardDetails: GalleryECard,
  scenario: SignUpScenario,
  template: string,
  theme: string,
  font?: string,
  startWithCtaRedirectUrlPath?: string
) => {
  if ((cardDetails.variationType === 'savethedate' || cardDetails.variationType === 'invite') && cardDetails.isJoyPrintable) {
    const resultUrl = queryString.parseUrl(
      EXT_APP_ROOT_URL + `/cards/wedding/${VARIATION_TYPE_TO_NAMED_CATEGORY_PATH[cardDetails.variationType]}/product/${theme}`
    );

    resultUrl.query.scenario = scenario;
    return queryString.stringifyUrl(resultUrl);
  }

  const resultUrl = queryString.parseUrl(startWithCtaRedirectUrlPath ? getRootUrl(startWithCtaRedirectUrlPath) : baseCreateWeddingUrl);
  const queryParams = resultUrl.query;
  queryParams.scenario = scenario;
  queryParams.template = template;
  queryParams.theme = theme;

  if (font) {
    queryParams.font = font;
  }
  return queryString.stringifyUrl(resultUrl);
};

export const getGuestSiteUrl = (selectedVariant: GalleryECard, themeId: string, currentQueryParams: ParsedQuery<string | boolean>) => {
  const { variationType: layout, font } = selectedVariant;
  const resultUrl = queryString.parseUrl(EXT_APP_ROOT_URL + `/${demoEventHandle}/welcome`);
  const queryParams = resultUrl.query;
  themeId && (queryParams.theme = themeId);
  layout && (queryParams.layout = layout);
  font && (queryParams.font = font);
  if (Object.keys(currentQueryParams).length > 0) {
    Object.keys(currentQueryParams).forEach(key => {
      queryParams[key] = currentQueryParams[key] as string;
    });
  }
  return queryString.stringifyUrl(resultUrl);
};
